import React, { FunctionComponent } from 'react'

import firebase from 'firebase/app'

import Link from 'gatsby-link'

import Grid from '@material-ui/core/Grid'

import GoogleAnalyticsLoader from '../components/google-analytics-loader'

type DefaultFooterProps = {
  firebase?: firebase.FirebaseApp
}

const DefaultFooter: FunctionComponent<DefaultFooterProps> = ({
  firebase: firebaseApp,
}) => {
  return (
    <>
      <footer>
        <div
          style={{
            color: '#555555',
            fontSize: '0.75rem',
            fontFamily: 'Roboto',
            margin: '0 auto',
            maxWidth: 960,
            padding: '0px 1.0875rem 1.45rem',
          }}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              © 2022 Adjective Noun
            </Grid>
            <Grid
              container
              item
              xs={6}
              style={{ alignItems: 'right', textAlign: 'right' }}>
              <Grid item xs={4} sm={8} />
              <Grid item xs={4} sm={2}>
                <Link
                  to="/terms/privacy_policy"
                  style={{ textDecoration: 'none' }}>
                  Privacy
                </Link>
              </Grid>
              <Grid item xs={4} sm={2}>
                <Link
                  to="/terms/terms_of_service"
                  style={{ textDecoration: 'none' }}>
                  Terms
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </footer>
      <GoogleAnalyticsLoader firebase={firebaseApp} />
    </>
  )
}

export default DefaultFooter
