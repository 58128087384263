import firebase from 'firebase/app'

import React, { FunctionComponent } from 'react'

type GoogleAnalyticsLoaderProps = {
  firebase?: firebase.FirebaseApp
}

const GoogleAnalyticsLoader: FunctionComponent<GoogleAnalyticsLoaderProps> = ({
  firebase: firebaseApp,
}) => {
  React.useEffect(() => {
    const asyncEffect = async () => {
      if (firebaseApp) {
        // Lazily load firebase analytics as Gatsby static rendering has issues
        // with loading the library properly.
        const firebaseAnalytics = await import('firebase/analytics')

        const analytics = firebaseAnalytics.getAnalytics(firebaseApp)
        void analytics
      }
    }

    void asyncEffect()
  }, [firebaseApp])

  return <></>
}

export default GoogleAnalyticsLoader
